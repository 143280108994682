define("discourse/plugins/discourse-events/discourse/components/modal/add-event", ["exports", "@ember/component", "I18n", "@ember/object", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _I18n, _object, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="add-event-modal"
    @title={{this.title}}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
  >
    <:body>
      {{event-form
        event=model.event
        updateEvent=(action 'updateEvent')}}
    </:body>
    <:footer>
      {{d-button action=(action "saveEvent") class="btn-primary" label="add_event.event_add"}}
      <a href class="clear" {{on "click" (fn this.clear)}}>{{i18n 'add_event.event_clear'}}</a>
    </:footer>
  </DModal>
  
  */
  {
    "id": "K+1wd1hh",
    "block": "[[[8,[39,0],[[24,0,\"add-event-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[[30,0,[\"title\"]],[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[28,[35,1],null,[[\"event\",\"updateEvent\"],[[30,0,[\"model\",\"event\"]],[28,[37,2],[[30,0],\"updateEvent\"],null]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[1,[28,[35,3],null,[[\"action\",\"class\",\"label\"],[[28,[37,2],[[30,0],\"saveEvent\"],null],\"btn-primary\",\"add_event.event_add\"]]]],[1,\"\\n    \"],[11,3],[24,6,\"\"],[24,0,\"clear\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"clear\"]]],null]],null],[12],[1,[28,[35,6],[\"add_event.event_clear\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-events/discourse/components/modal/add-event.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"@closeModal\"],false,[\"d-modal\",\"event-form\",\"action\",\"d-button\",\"on\",\"fn\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/modal/add-event.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_obj = {
    title: _I18n.default.t("add_event.modal_title"),
    clear() {
      event?.preventDefault();
      this.set("bufferedEvent", null);
    },
    actions: {
      saveEvent() {
        if (this.valid) {
          this.get("model.update")(this.bufferedEvent);
          this.closeModal();
        } else {
          this.set("flash", _I18n.default.t("add_event.error"));
        }
      },
      updateEvent(event, valid) {
        this.set("bufferedEvent", event);
        this.set("valid", valid);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "clear", [_object.action], Object.getOwnPropertyDescriptor(_obj, "clear"), _obj)), _obj)));
});