define("discourse/plugins/discourse-events/discourse/templates/connectors/user-preferences-nav/event-ical-key", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="nav-webcal-keys">
    {{#link-to "preferences.webcal-keys"}}
      {{i18n "webcal_preferences.webcal_keys"}}
    {{/link-to}}
  </li>
  
  */
  {
    "id": "G3muER8u",
    "block": "[[[10,\"li\"],[14,0,\"nav-webcal-keys\"],[12],[1,\"\\n\"],[6,[39,0],[\"preferences.webcal-keys\"],null,[[\"default\"],[[[[1,\"    \"],[1,[28,[35,1],[\"webcal_preferences.webcal_keys\"],null]],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/connectors/user-preferences-nav/event-ical-key.hbs",
    "isStrictMode": false
  });
});