define("discourse/plugins/discourse-events/discourse/templates/components/events-log-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <td>
    {{bound-date log.created_at}}
  </td>
  
  <td class="log-level">
    {{log.level}}
  </td>
  
  <td class="log-context">
    {{log.context}}
  </td>
  
  <td>
    {{log.message}}
  </td>
  
  */
  {
    "id": "4MCZMLcJ",
    "block": "[[[10,\"td\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[31,1,[\"created_at\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"log-level\"],[12],[1,\"\\n  \"],[1,[31,1,[\"level\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"log-context\"],[12],[1,\"\\n  \"],[1,[31,1,[\"context\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n  \"],[1,[31,1,[\"message\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"bound-date\",\"log\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/components/events-log-row.hbs",
    "isStrictMode": false
  });
});